<template>
  <div class="auth-form-wrapper">
    <div class="auth-form-container">
      <div class="auth-form">
        <ShowTitle text="免密碼登入" type="title" />
        <i
          @click="$router.push({ path: '/' })"
          class="
            auth-close-btn
            pointer
            el-icon-close
            basic-hover
            ease-in-out-03s
            font-gray
          "
        ></i>

        <el-form>
          <el-form-item>
            <el-input
              style="width: 260px"
              placeholder="電子郵件"
              type="email"
              v-model="dataObj.email"
            ></el-input>
          </el-form-item>
          <ShowTitle
            text="系統會發送一封郵件到你的信箱，點擊網址後即可快速登入。該網址使用一次後即會永久失效。"
            type="field-sub-title"
          />
        </el-form>

        <div class="form-button-container">
          <el-button
            class="ease-in-out-03s basic-submit-btn"
            :loading="submitLoading"
            @click="HandleSubmit()"
          >
            {{ signinBtnText }}
          </el-button>
          <div class="margin-top-sm"></div>
          <router-link
            class="ease-in-out-03s basic-hover font-blue"
            to="/user/signin"
          >
            一般帳號密碼登入
          </router-link>
          <div class="margin-top-xl margin-bottom-xs">還沒有帳號嗎？</div>
          <router-link
            class="ease-in-out-03s basic-hover font-blue"
            to="/user/signup"
          >
            註冊
          </router-link>
          <!-- <el-button v-if="!authorized" @click="HandleFacebookLogin()">
            Facebook Login
          </el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import { userSignin, userEmailVerifyResend } from "@/api/user";
import Cookies from "js-cookie";

export default {
  mixins: [mainCommon],
  data() {
    return {
      authorized: null,
      signinBtnText: "發送",
      isPassword: "password",
      submitLoading: false,
      dataObj: {
        email: null,
        password: null,
      },
    };
  },
  mounted() {
    let _this = this;
    window.fbAsyncInit = () => {
      FB.init({
        appId: "2850884075203797",
        cookie: true,
        xfbml: true,
        version: "v12.0",
      });
      FB.AppEvents.logPageView();
      FB.getLoginStatus((res) => {
        _this.HandleFacebookStatusCallback(res);
      });
    };
  },
  methods: {
    HandleFacebookLogout() {
      let _this = this;
      FB.logout((res) => {
        _this.HandleFacebookStatusCallback(res);
      });
    },
    HandleFacebookStatusCallback(res) {
      let _this = this;
      switch (res.status) {
        case "connected":
          _this.authorized = true;
          FB.api("/me?fields=name,id,email,picture", (res) => {
            this.HandleFacebookSignup(res);
            // https://graph.facebook.com/10226213976642157/picture?width=9999
          });
          break;
        case "not_authorized":
        case "unknown":
          _this.authorized = false;
          break;
      }
    },
    HandleFacebookLogin() {
      let _this = this;
      FB.login(
        (res) => {
          _this.HandleFacebookStatusCallback(res);
        },
        {
          scope: "email,public_profile",
          return_scopes: true,
        }
      );
    },
    HandleFacebookSignup(res) {
      console.log(res);
    },
    HandleSubmit() {
      this.submitLoading = true;
      userSignin(this.dataObj)
        .then((res) => {
          if (res.code === 20000) {
            return this.HandleNotActive();
          }
          let token = res.data.token;
          localStorage.setItem("authorization", token.slice(0, 30));
          Cookies.set("authorization", token.slice(30, 64));
          this.pushNotification(res.message);
          setTimeout(() => {
            location.href = "/user/profile";
          }, 500);
        })
        .catch(() => {
          this.submitLoading = false;
        })
        .finally(() => {
          this.signinBtnText = "登入";
        });
    },
    HandleNotActive() {
      this.submitLoading = true;
      this.signinBtnText = "正在發送驗證碼，請稍候";
      Cookies.set("emailVerify", this.dataObj.email);
      userEmailVerifyResend({
        email: this.dataObj.email || Cookies.get("emailVerify"),
      })
        .then(() => {
          setTimeout(() => {
            this.$router.push({ path: "/user/email-verify" });
          }, 500);
        })
        .finally(() => {
          this.signinBtnText = "登入";
          this.submitLoading = false;
        });
    },
  },
};
</script>